
import axios from "axios"
import { authCheck } from "../auth/authCheck";
import { authGuard } from "../auth/authGuard";
const https = require('https');

export default {

  async post(page, collection, method, object, surpressErrors) {
    return await new Api().post(page, collection, method, object, surpressErrors);
  },

  async first(page, collection, object, surpressErrors) {
    var list = await this.post(page, collection, "list", object, surpressErrors);
    if (list == null) {
      return null;
    }
    if (list.length > 0) {
      return list[0];
    } else {
      return null;
    }
  }

}

class Api {

  async post(page, collection, method, object, surpressErrors) {
    var config = {}; 
    if (page.$auth.isAuthenticated) {
      const accessToken = await page.$auth.getTokenSilently();
      config.headers = {};
      config.headers.Authorization =  `Bearer ${accessToken}`;
    }
    var response = null;
    var uri = process.env.VUE_APP_API_URL + "/" + collection + "/" + method;
    try {
      response = await axios.post(uri, object, config);
    } catch (error) {
      this.processError(page, error, surpressErrors)
      return;
    }
    var json = response.data;
    if (json.status == "OK") {
      console.log(method + " " + collection);
      if (process.env.NODE_ENV === 'development') {
//        console.log(JSON.stringify(json.data));
      }
      return json.data;
    } else {
      let validationError = new Error(json.message);
      validationError.name = "ValidationError"
      this.processError(page, validationError, surpressErrors)
    }
  }

  processError(page, error, surpressErrors) {
    let message = error;
    if (error.message) {
      message = error.message;
    }
    if (error.response && error.response.statusText) {
      message = error.response.statusText;
    }
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message;
    }
    if (surpressErrors) {
      return;
    }
    console.log(message);
    try {
      page.$toast.error(" ", message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
    } catch (err) {
      console.log("toast error", err);
    }
    error.message = message;
    throw error;
  }

}
